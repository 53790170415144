import { NetworkService } from "./NetworkService";
import { SubscriptionService } from "./SubscriptionService";

export default class UserService extends NetworkService {
  constructor() {
    super();
  }

  static PROFESSIONAL_TYPES = {
    doctor: "Médico",
    dentist: "Dentista",
    other: "Outro",
  };

  static USER_TYPE = {
    admin: "Administrador",
    professional: "Profissional",
    student: "Estudante",
  };

  list = async (filters) => {
    const res = await this.get(this.endpoints.users, filters);
    res.data = res.data.map((user) => this.prepareUser(user));
    return res;
  };
  create = (params) => {
    return this.post(this.endpoints.users, params);
  };
  update = (id, params) => {
    return this.put(`${this.endpoints.users}/${id}`, params);
  };
  show = async (id, params) => {
    const res = await this.get(`${this.endpoints.users}/${id}`, params);
    res.data = this.prepareUser(res.data);
    return res;
  };
  delete = (id) => {
    return this.delete(`${this.endpoints.users}/${id}`);
  };
  login = async (params) => {
    let res = await this.post(this.endpoints.login, params);
    if (res.data.type == "admin") {
      return res;
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };
  forgotPassword = (params) => {
    return this.post(this.endpoints.forgotPassword, params);
  };
  logout = () => {
    return this.put(`${this.endpoints.users}/logout`);
  };
  settings = (key) => {
    return this.get(`${this.endpoints.settings}/${key}`);
  };
  updateSettings = (key, value) => {
    return this.put(`${this.endpoints.settings}/${key}`, {
      value,
    });
  };
  homeInfo = () => {
    return this.get(`${this.endpoints.homeInfo}`);
  };
  listCrashes = () => {
    return this.get(`${this.endpoints.appCrashes}`);
  };
  showCrash = (id) => {
    return this.get(`${this.endpoints.appCrashes}/${id}`);
  };
  planDetails = (params) => {
    return this.get(`${this.endpoints.planDetails}`, params);
  };
  subscribeUser = (params) => {
    return this.post(this.endpoints.subscribeUser, params);
  };
  unsubscribeUser = (params) => {
    return this.post(this.endpoints.unsubscribeUser, params);
  };
  sendNotification = (params) => {
    return this.post(`${this.endpoints.sendNotification}`, params);
  };
  sendEmail = (params) => {
    return this.post(`${this.endpoints.sendEmail}`, params);
  };
  prepareUser = (data) => {
    const subsService = new SubscriptionService();

    const hasActiveStripeSubs =
      data.subs &&
      (data.subs.stripe_status === "active" ||
        data.subs.stripe_status === "trialing");

    const hasActiveIAPSubs =
      data.inapp_subs &&
      (data.inapp_subs.plan_status === "paid" ||
        data.inapp_subs.plan_status === "trial");

    data.isPremium = hasActiveIAPSubs || hasActiveStripeSubs;
    data.account_type = UserService.USER_TYPE[data.type];
    data.professionalType =
      UserService.PROFESSIONAL_TYPES[data.professional_type];
    data.subscriptionConfig = hasActiveIAPSubs
      ? subsService.prepareSubscriptionForIAP(data.inapp_subs)
      : data.subs
      ? subsService.prepareSubscriptionForStripe(data.subs)
      : null;

    if (data.phone) {
      data.formattedPhone = `${data.area_phone}${data.phone}`.toPattern(
        "phone",
        "(##) #####-####"
      );
    }

    return data;
  };

  listFeedback = () => {
    return this.get(this.endpoints.feedback);
  };

  listCRMs = (id) => {
    return this.get(this.endpoints.userCrm, { user_id: id });
  };

  updateCRM = (id, params) => {
    return this.put(`${this.endpoints.userCrm}/${id}`, params);
  };
}
