export default {
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.calculateWidth);
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      isCancelable: false,
      crmColumns: [
        { name: "Número" },
        { name: "UF" },
        { name: "Validado" },
        { name: "Ações" },
      ],
      addressColumns: [
        {
          name: "Nome do local",
          sortName: null,
          order: null,
        },
        {
          name: "Endereço",
          sortName: null,
          order: null,
        },
        {
          name: "Complemento",
          sortName: null,
          order: null,
        },
      ],
      columns: [
        {
          name: "Paciente",
          sortName: null,
          order: null,
        },
        {
          name: "Assinatura digital",
          sortName: null,
          order: null,
        },
        {
          name: "Data",
          sortName: null,
          order: null,
        },
        {
          name: "PDF",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    async getData() {
      try {
        let filters = {
          user_id: this.id,
        };
        const res = await this.$userService.show(this.id, {
          address: true,
          device_information: true,
          crms: true,
        });
        const medicalRecords = await this.$medicalRecordsService.list(filters);
        const prescriptions = await this.$prescriptionsService.list(filters);

        let data = res.data;
        data.medicalRecords = medicalRecords.data;
        data.prescriptions = prescriptions.data;

        this.$set(this, "item", data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    openPDF(item) {
      if (item.pdf_url) {
        window.open(
          `${process.env.VUE_APP_IMG_BASE_URL}${item.pdf_url}`,
          "_blank"
        );
      }
    },
    async handlePremium() {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: this.item.isPremium ? "Remover Premium" : "Tornar Premium",
        message: this.item.isPremium
          ? "Deseja remover a assinatura premium para este usuário?"
          : "Deseja dar uma assinatura premium para este usuário?",
        confirmCallback: async () => {
          try {
            this.loadingItems = true;
            if (this.item.isPremium) {
              await this.$userService.unsubscribeUser({ user_id: this.id });
              this.$eventBus.$emit("showToast", {
                title: "Assinatura removida",
                msg: `Assinatura removida com sucesso`,
              });
            } else {
              await this.$userService.subscribeUser({ user_id: this.id });
              this.$eventBus.$emit("showToast", {
                title: "Assinatura efetuada",
                msg: `Assinatura efetuada com sucesso`,
              });
            }
            this.getData();
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async handleBlock() {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: this.item.active ? "Bloquear Usuário" : "Desbloquear Usuário",
        message: this.item.active
          ? "Deseja bloquear este usuário?"
          : "Deseja desbloquear este usuário?",
        confirmCallback: async () => {
          try {
            this.loadingItems = true;
            await this.$userService.update(this.id, {
              active: !this.item.active,
            });
            this.$eventBus.$emit("showToast", {
              title: this.item.active
                ? "Usuário bloqueado"
                : "Usuário desbloqueado",
              msg: this.item.active
                ? `Usuário bloqueado com sucesso`
                : "Usuário desbloqueado com sucesso",
            });
            this.getData();
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    toggleValid(item, index) {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: item.valid ? "Invalidar CRM" : "Validar CRM",
        message: item.valid
          ? "Deseja invalidar este CRM?"
          : "Deseja validar este CRM?",
        confirmCallback: async () => {
          try {
            await this.$userService.updateCRM(item.id, {
              valid: !item.valid,
            });
            this.$eventBus.$emit("showToast", {
              title: item.valid ? "CRM invalidado" : "CRM validado",
              msg: item.valid
                ? `CRM invalidado com sucesso`
                : "CRM validado com sucesso",
            });
            this.loadingItems = true;
            this.getData();
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
};
