<template>
  <div id="home">
    <Navbar current="Visão geral" />
    <Sidebar />
    <Container col="col-12">
      <!-- HEADER  -->
      <Header
        icon="fa-solid fa-chart-simple"
        title="Visão geral"
        :subtitle="`Informações abaixo pertencem ao mês de `"
        :featuredSubtitle="currentMonth | capitalize"
      />
      <!-- LOADING CARDS -->
      <div v-if="loadingItems" class="row">
        <div class="col-12">
          <div class="row placeholder-glow">
            <div class="col-4">
              <span class="placeholder placeholder-lg col-12"></span>
            </div>
            <div class="col-4">
              <span class="placeholder col-12"></span>
            </div>
            <div class="col-4">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="row placeholder-glow">
            <div class="col-4">
              <span class="placeholder col-12"></span>
            </div>
            <div class="col-4">
              <span class="placeholder col-12"></span>
            </div>
            <div class="col-4">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- CARDS -->
      <div v-else id="cards_home">
        <div class="row">
          <div class="col-12 col-lg-4">
            <router-link
              class="card lift"
              tag="a"
              :to="$paths.prescriptions.list"
            >
              <div class="card-body">
                <h2 class="text-dark">
                  <i
                    class="fa-solid fa-prescription-bottle-medical text-primary me-1"
                  ></i>
                  Receitas
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info.month?.recipes }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link
              class="card lift"
              tag="a"
              :to="$paths.medical_records.list"
            >
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-clipboard-user text-primary me-1"></i>
                  Prontuários
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.month?.medicalRecords }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link
              class="card lift"
              tag="a"
              :to="$paths.contents.categories.list"
            >
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-regular fa-file-lines text-primary"></i>
                  Conteúdos
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.month?.contents }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link class="card lift" tag="a" :to="$paths.users.list">
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-user-group me-1 text-primary"></i>
                  Usuários
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.month?.user }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link class="card lift" tag="a" :to="$paths.patients.list">
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-user-injured me-1 text-primary"></i>
                  Pacientes
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.month?.patients }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link class="card lift" tag="a" :to="$paths.users.list">
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-coins me-1 text-primary"></i>
                  Assinantes
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.month?.subscribers }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
        </div>
        <h2 class="text-paragraph mt-4">Informações de todo o período</h2>
        <hr class="mb-5" />
        <div class="row">
          <div class="col-12 col-lg-4">
            <router-link
              class="card lift"
              tag="a"
              :to="$paths.prescriptions.list"
            >
              <div class="card-body">
                <h2 class="text-dark">
                  <i
                    class="fa-solid fa-prescription-bottle-medical text-primary me-1"
                  ></i>
                  Receitas
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.all?.recipes }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link
              class="card lift"
              tag="a"
              :to="$paths.medical_records.list"
            >
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-clipboard-user me-1 text-primary"></i>
                  Prontuários
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.all?.medicalRecords }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link
              class="card lift"
              tag="a"
              :to="$paths.contents.categories.list"
            >
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-regular me-1 fa-file-lines text-primary"></i>
                  Conteúdos
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.all?.contents }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link class="card lift" tag="a" :to="$paths.users.list">
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-user-group me-1 text-primary"></i>
                  Usuários
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.all?.user }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link class="card lift" tag="a" :to="$paths.patients.list">
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-user-injured text-primary me-1"></i>
                  Pacientes
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.all?.patients }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-4">
            <router-link class="card lift" tag="a" :to="$paths.users.list">
              <div class="card-body">
                <h2 class="text-dark">
                  <i class="fa-solid fa-coins me-1 text-primary"></i>
                  Assinantes
                </h2>
                <div class="display-2 my-3 text-dark">
                  <span>
                    {{ info?.all?.subscribers }}
                  </span>
                </div>
                <p class="text-primary mb-0">Ver mais</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import HomeController from "./HomeController";
export default HomeController;
</script>

<style>
#cards_home h2,
#cards_home p,
#cards_home span {
  font-weight: 700;
}

#cards_home p {
  font-size: 16px;
}

#cards_home span {
  font-size: 60px;
}
</style>
